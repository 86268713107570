<section>
  <div class="container-full bg-theme">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <app-breadcrumbs [darkTheme]="true" [breadcrumbLinks]="breadcrumbLinks"></app-breadcrumbs>
          <h1 i18n="@@errorTitle" class="title is-1 has-text-light">
            Page not found
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container m-t-sm m-b-xxl">
    <!-- OECD AI Expert Network -->
    <div class="columns">
      <div class="column">
        <h2 i18n="@@errorTitle1" class="title is-2 m-b-gutter p-t-xl">
          Oops!
        </h2>
        <p>
          We couldn't find the page you're looking for. If you would like to report a broken link, please <a
            routerLink="/contact"> click here</a>.
        </p>
        <p>
          Otherwise, you may want to use the search or the main navigation to find what you're looking for.
        </p>
        <p>
          <a i18n="@@errorConclusion" class="button" routerLink="/">Return to homepage</a>
        </p>
      </div>
    </div>
  </div>
</section>
