<ng-container *ngIf="workingGroup">
  <app-working-group-head
    [selectedNavItem]="workingGroup.name"
  ></app-working-group-head>
  <div class="container m-t-rel">
    <app-breadcrumbs [breadcrumbLinks]="breadcrumbLinks"></app-breadcrumbs>
  </div>
  <div class="container m-b-xxl">
    <!-- Working group card-->
    <div class="columns m-b-xl">
      <div class="column">
        <h2 class="title is-2">
          {{ workingGroup.name }}
        </h2>
        <div [innerHTML]="workingGroup.content"></div>

        <ng-container *ngIf="workingGroup.posts && workingGroup.posts.length">
          <h2 class="title is-2">Blog post</h2>
          <div class="columns flex flex-wrap" *ngIf="workingGroup.posts">
            <div
              class="column is-6-mobile is-4-tablet is-4-desktop"
              *ngFor="let post of workingGroup.posts"
            >
              <app-post-card [post]="post"></app-post-card>
            </div>
            <div
              class="column is-6-mobile is-4-tablet is-4-desktop"
              *ngIf="loadingPosts"
            >
              <div class="lc lc--author">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div></div
        ></ng-container>

        <app-document-list
          [documents]="workingGroup.documents"
          *ngIf="workingGroup.documents && workingGroup.documents.length"
        ></app-document-list>
      </div>
    </div>
  </div>
</ng-container>
