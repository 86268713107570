<div class="columns m-t-xl m-b-gutter">
  <div class="column is-12-desktop">
    <div class="card">
      <div class="columns">
        <div class="column is-12-desktop">
          <h2 class="title is-2">{{ title }}</h2>
        </div>
      </div>
      <div class="columns flex flex-wrap">
        <div
          class="column is-12-touch is-6-desktop"
          *ngIf="!loading && documents && !documents.length"
        >
          <p i18n="@@documentListError">No document found.</p>
        </div>

        <div
          class="column is-12-touch is-6-desktop"
          *ngFor="let document of documents"
        >
          <app-document-card [document]="document"></app-document-card>
        </div>
        <div class="column is-12-touch is-6-desktop" *ngIf="loading">
          <div class="lc lc--docs lc--docs--white">
            <div></div>
            <div>
              <div></div>
              <div></div>
            </div>
            <div></div>
          </div>
        </div>
        <div class="column is-12-touch is-6-desktop" *ngIf="loading">
          <div class="lc lc--docs lc--docs--white">
            <div></div>
            <div>
              <div></div>
              <div></div>
            </div>
            <div></div>
          </div>
        </div>
        <div class="column is-12-touch is-6-desktop" *ngIf="loading">
          <div class="lc lc--docs lc--docs--white">
            <div></div>
            <div>
              <div></div>
              <div></div>
            </div>
            <div></div>
          </div>
        </div>
        <div class="column is-12-touch is-6-desktop" *ngIf="loading">
          <div class="lc lc--docs lc--docs--white">
            <div></div>
            <div>
              <div></div>
              <div></div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
