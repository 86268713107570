import { NgClass, NgFor, NgIf, isPlatformBrowser } from '@angular/common'
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core'
import { Power2, gsap } from 'gsap'

import { RouterLink } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { policyAreas } from '../../../../locale/policy-areas/policy-areas.content.en'
import { principles } from '../../../../locale/principles/principles.content.en'
import { SmartLinkDirective } from '../../directives/smart-link.directive'
import { PolicyArea } from '../../interfaces/policy-area.interface'
import { Principle } from '../../interfaces/principle.interface'
import { Area } from '../../interfaces/resources/area.interface'
import { KeyTheme } from '../../interfaces/resources/key-theme.interface'
import { WorkingGroup } from '../../interfaces/resources/working-group.interface'
import { AreaService } from '../../services/area.service'
import { EventService } from '../../services/event.service'
import { PostService } from '../../services/post.service'
import { WorkingGroupService } from '../../services/working-group.service'

@Component({
  selector: 'app-header-touch',
  templateUrl: './header-touch.component.html',
  styleUrls: ['./header-touch.component.scss'],
  standalone: true,
  imports: [SmartLinkDirective, NgClass, NgFor, RouterLink, NgIf]
})
export class HeaderTouchComponent implements OnInit {
  @ViewChild('menu', { static: true }) menuEl: ElementRef
  @ViewChild('menuItems', { static: true }) menuItemsEl: ElementRef

  principles: Principle[] = principles
  policyAreas: PolicyArea[] = policyAreas
  areas: Area[]
  keyThemes: KeyTheme[]
  workingGroups: WorkingGroup[]

  environment = environment

  showMenu: boolean
  showAboutDropdown: boolean
  showPriorityIssuesDropdown: boolean
  showToolsDropdown: boolean
  showResourcesDropdown: boolean
  fixedHeader = false
  showSearch = false

  showAvailableLanguages = false

  currentPath: string
  alternativeLangPath: string

  activeTab: string

  dropdownMappings: { [key: string]: string } = {
    // Blog.
    '/wonk': 'blog',

    // Live data.
    '/data': 'liveData',

    // Initiative registry.
    '/dashboards/overview': 'registry',
    '/dashboards/target-groups': 'registry',
    '/dashboards/registry': 'registry',
    '/dashboards/policy-initiatives': 'registry',
    '/dashboards/intergovernmental': 'registry',
    '/dashboards/national': 'registry',
    '/dashboards/ai-recap': 'registry',

    // Priority issues.
    '/genai': 'priorityIssues',
    '/genai/issues': 'priorityIssues',

    '/site/compute-climate': 'priorityIssues',
    '/site/incidents': 'priorityIssues',
    '/site/ai-futures': 'priorityIssues',
    '/site/risk-accountability': 'priorityIssues',

    '/work-innovation-productivity-skills': 'priorityIssues',
    '/dashboards/work-innovation-productivity-skills': 'priorityIssues',

    //Tools.
    '/catalogue': 'tools',
    '/incidents': 'tools',

    //Resources.
    '/ai-principles': 'resources',
    '/dashboards/ai-principles': 'resources',
    '/policy-areas': 'resources',
    '/dashboards/policy-areas': 'resources',
    '/videos': 'resources',
    '/ai-publications': 'resources',

    // About.
    '/network-of-experts': 'about',
    '/about': 'about',
    '/partners': 'about'
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private elementRef: ElementRef,
    private postService: PostService,
    private eventService: EventService,
    private areaService: AreaService,
    private workingGroupService: WorkingGroupService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const promise: Promise<KeyTheme[]> = this.postService.listUnpaginated({
        postType: 'wips-key-themes'
      }) as Promise<KeyTheme[]>

      promise.then((keyThemeRes: KeyTheme[]) => {
        this.keyThemes = keyThemeRes.filter((kT) => kT.slug !== 'overview')
      })

      this.eventService.routeChanged.subscribe(
        (routeChanged: { url: string }) => {
          this.hideDropdown()
          this.showSearch = false
          this.currentPath = routeChanged.url

          this.activeTab = this.setActiveTab(this.currentPath)
        }
      )

      this.areaService.list().then((areas: Area[]) => {
        const trendsAndDataArea: Area = areas.find(
          (a) => a.slug === 'trends-data'
        )

        if (trendsAndDataArea) {
          this.areas = areas
            .filter((a) => a.parent === trendsAndDataArea.id)
            .sort((a, b) => b.sortOrder - a.sortOrder)
        }
      })
    }

    this.workingGroupService
      .list()
      .then(
        (workingGroupRes: WorkingGroup[]) =>
          (this.workingGroups = workingGroupRes)
      )

    this.areaService.list().then((areas: Area[]) => {
      const trendsAndDataArea: Area = areas.find(
        (a) => a.slug === 'trends-data'
      )

      if (trendsAndDataArea) {
        this.areas = areas.filter((a) => a.parent === trendsAndDataArea.id)
      }
    })

    this.eventService.routeChanged.subscribe(
      (routeChanged: { url: string }) => {
        this.currentPath = routeChanged.url
        this.alternativeLangPath = this.currentPath.includes('wonk')
          ? '/wonk'
          : this.currentPath

        this.activeTab = this.setActiveTab(this.currentPath)
      }
    )
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    // We track clicks to close dropdown if open and click outside
    if (
      !this.elementRef.nativeElement
        .querySelector('#main-navbar')
        .contains(target)
    ) {
      this.showAboutDropdown = false
      this.showPriorityIssuesDropdown = false
      this.showToolsDropdown = false
      this.showResourcesDropdown = false
    }
  }

  toggleMenu(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.showMenu = !this.showMenu
      this.triggerMenuAnimation()

      if (this.showMenu) {
        this.renderer.addClass(document.querySelector('html'), 'is-clipped')
      } else {
        this.renderer.removeClass(document.querySelector('html'), 'is-clipped')

        // remove is-active class from all dropdowns
        const dropdowns = document.querySelectorAll('.navbar-item.has-dropdown')
        dropdowns.forEach((dropdown) => {
          dropdown.classList.remove('is-active')
        })
      }
    }
  }

  hideDropdown() {
    this.showAboutDropdown = false
    this.showPriorityIssuesDropdown = false
    this.showToolsDropdown = false
    this.showResourcesDropdown = false
    this.fixedHeader = false
  }

  triggerMenuAnimation() {
    const mainNavbar = document.getElementById('main-navbar')

    if (this.showMenu) {
      if (mainNavbar) {
        mainNavbar.classList.add('is-open')
      }

      gsap.set('#menu-items', {
        top: '64px'
      })
      gsap.to('#menu-items', {
        duration: 0.25,
        opacity: 1,
        ease: Power2.easeInOut
      })
    } else {
      if (mainNavbar) {
        mainNavbar.classList.remove('is-open')
      }

      gsap.to('#menu-items', {
        duration: 0.25,
        opacity: 0,
        ease: Power2.easeOut
      })
      gsap.set('#menu-items', {
        top: '-100%',
        delay: '0.25'
      })
    }
  }

  setActiveTab(path: string): string {
    const matchingKey = Object.keys(this.dropdownMappings).find((key) =>
      path.startsWith(key)
    )

    return matchingKey ? this.dropdownMappings[matchingKey] : ''
  }
}
