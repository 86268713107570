import { Component, HostListener, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import * as moment from 'moment'

import { BreadcrumbLink } from '../../shared/interfaces/breadcrumb-link.interface'

import { metaDataContent } from '../../../locale/meta-data/meta-data.content.en'
import { Area } from '../../shared/interfaces/resources/area.interface'
import { AreaService } from '../../shared/services/area.service'
import { BreadcrumbService } from '../../shared/services/breadcrumb.service'
import { MetaService } from '../../shared/services/meta.service'

@Component({
  selector: 'app-live-data-from-partners',
  templateUrl: './live-data-from-partners.component.html',
  styleUrls: ['./live-data-from-partners.component.scss']
})
export class LiveDataFromPartnersComponent implements OnInit {
  breadcrumbLinks: BreadcrumbLink[]

  areas: Area[]
  selectedArea: Area

  selectedVisualization: string

  today: string = moment().format('D/MM/Y')
  showRegionalDisclaimerModal = false

  constructor(
    private areaService: AreaService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    breadcrumbService: BreadcrumbService,
    metaService: MetaService
  ) {
    this.breadcrumbLinks = breadcrumbService.getBreadCrumbLinks(
      'liveDataFromPartners'
    )
    metaService.setTags(metaDataContent.liveDataFromPartners)
  }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      this.areaService.list().then((areas: Area[]) => {
        const trendsAndDataArea: Area = areas.find(
          (a) => a.slug === 'trends-data'
        )

        if (trendsAndDataArea) {
          this.areas = areas
            .filter((a) => a.parent === trendsAndDataArea.id)
            .sort((a, b) => {
              return b.sortOrder - a.sortOrder
            })

          this.selectedArea =
            this.areas.find((a) => a.slug === queryParams.selectedArea) ||
            this.areas[0]
        }
      })

      this.selectedVisualization = queryParams.selectedVisualization
    })
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement) {
    // We track clicks to close model if open and click outside
    if (
      this.showRegionalDisclaimerModal &&
      target.className === 'modal-background'
    ) {
      this.showRegionalDisclaimerModal = false
    }
  }
}
