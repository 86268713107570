import { Component, Input } from '@angular/core'
import { Document } from '../../../interfaces/resources/document.interface'
import { DocumentCardComponent } from '../document-card/document-card.component'
import { NgFor, NgIf } from '@angular/common'

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  standalone: true,
  imports: [DocumentCardComponent, NgIf, NgFor]
})
export class DocumentListComponent {
  @Input() documents: Document[]
  @Input() loading: boolean
  @Input() title = 'Documents'
}
