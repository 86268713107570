<!-- Main navbar -->
<div class="container-full bg-primary">
  <div class="container">
    <nav
      class="navbar"
      role="navigation"
      aria-label="dropdown navigation"
      id="main-navbar"
      #menu
    >
      <div class="nav--static">
        <div class="nav-left">
          <a class="navbar__logo" routerLink="/" (click)="toggleMenu()">
            <img
              src="./assets/images/oecd-ai-logo.svg"
              alt="OECD AI Policy Observatory"
              width="168"
            />
          </a>
        </div>
        <div class="nav-right">
          <a
            (click)="toggleMenu()"
            (keydown)="toggleMenu()"
            tabindex="0"
            role="button"
            class="nav-icon"
            aria-label="Afficher et cacher le menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span class="bar bar1"></span>
            <span class="bar bar2"></span>
            <span class="bar bar3"></span>
          </a>
        </div>
      </div>
      <div class="nav-main bg-theme" #menuItems id="menu-items">
        <!-- Blog -->

        <a
          class="navbar-item"
          routerLink="/wonk"
          (click)="toggleMenu()"
          routerLinkActive="is-active"
          [ngClass]="{
            'is-active': activeTab === 'blog'
          }"
        >
          <span>Blog</span>
        </a>

        <!-- Live data -->
        <a
          class="navbar-item"
          routerLink="/data"
          [ngClass]="{
            'is-active': activeTab === 'liveData'
          }"
          (click)="toggleMenu()"
          routerLinkActive="is-active"
        >
          <span i18n="@@headerTrendsNavbarTitleTouch"> Live data</span>
        </a>

        <!-- Countries -->
        <a
          class="navbar-item"
          routerLink="/dashboards/overview"
          (click)="toggleMenu()"
          [ngClass]="{
            'is-active': activeTab === 'registry'
          }"
        >
          <span i18n="@@headerCountriesInitiativesNavbarTitleTouch">
            Initiative registry</span
          >
        </a>

        <!-- Priority issues -->
        <div
          class="navbar-item has-dropdown"
          (click)="showPriorityIssuesDropdown = !showPriorityIssuesDropdown"
          (keydown)="showPriorityIssuesDropdown = !showPriorityIssuesDropdown"
          [ngClass]="{ 'is-active': showPriorityIssuesDropdown }"
          tabindex="-1"
        >
          <a
            class="navbar-link"
            [ngClass]="{
              'is-active': activeTab === 'priorityIssues'
            }"
          >
            <span> Priority issues </span>
          </a>
          <div class="navbar-dropdown">
            <div class="navbar-item">
              <span class="has-text-grey">
                <i class="icon icon-Left m-r-md"></i>
                Back
              </span>
            </div>
            <a
              class="navbar-item"
              routerLink="/site/risk-accountability"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Risk & Accountability</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/site/incidents"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Incidents</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/site/compute-climate"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Compute & Climate</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/site/ai-futures"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> AI Futures</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/site/data-privacy"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> AI, Data & Privacy</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/site/health"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> AI & Health</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/genai"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Generative AI</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/work-innovation-productivity-skills"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> WIPS</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/site/ai-index"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold">AI Index</span>
            </a>
          </div>
        </div>

        <!-- Tools -->
        <div
          class="navbar-item has-dropdown"
          (click)="showToolsDropdown = !showToolsDropdown"
          (keydown)="showToolsDropdown = !showToolsDropdown"
          [ngClass]="{
            'is-active': showToolsDropdown
          }"
          tabindex="0"
        >
          <a
            class="navbar-link"
            [ngClass]="{
              'is-active': activeTab === 'tools'
            }"
          >
            <span> Tools </span>
          </a>
          <div class="navbar-dropdown">
            <div class="navbar-item">
              <span class="has-text-grey pointer">
                <i class="icon icon-Left m-r-md"></i>
                Back
              </span>
            </div>

            <a
              class="navbar-item"
              routerLink="/catalogue/overview"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Catalogue Tools & Metrics</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/incidents"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> AI Incidents Monitor</span>
            </a>
          </div>
        </div>

        <!-- Resources -->
        <div
          class="navbar-item has-dropdown"
          (click)="showResourcesDropdown = !showResourcesDropdown"
          (keydown)="showResourcesDropdown = !showResourcesDropdown"
          [ngClass]="{
            'is-active': showResourcesDropdown
          }"
          tabindex="0"
        >
          <a
            class="navbar-link"
            [ngClass]="{
              'is-active': activeTab === 'resources'
            }"
          >
            <span> Resources </span>
          </a>
          <div class="navbar-dropdown">
            <div class="navbar-item">
              <span class="has-text-grey pointer">
                <i class="icon icon-Left m-r-md"></i>
                Back
              </span>
            </div>

            <a
              class="navbar-item"
              routerLink="/ai-principles"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> The AI Principles</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/definitions"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold">Definitions</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/policy-areas"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Policy areas</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/ai-publications"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Publications</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/videos"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Videos</span>
            </a>
          </div>
        </div>

        <!-- About -->
        <div
          class="navbar-item has-dropdown"
          (click)="showAboutDropdown = !showAboutDropdown"
          (keydown)="showAboutDropdown = !showAboutDropdown"
          [ngClass]="{ 'is-active': showAboutDropdown }"
          tabindex="0"
        >
          <a
            class="navbar-link"
            [ngClass]="{ 'is-active': activeTab === 'about' }"
          >
            <span> About </span>
          </a>
          <div class="navbar-dropdown">
            <div class="navbar-item">
              <span class="has-text-grey pointer">
                <i class="icon icon-Left m-r-md"></i>
                Back
              </span>
            </div>

            <a
              class="navbar-item"
              routerLink="/about/the-context"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Context</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/about/what-we-do"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> What we do</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/network-of-experts"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Network of Experts</span>
            </a>
            <a
              class="navbar-item"
              routerLink="/partners"
              routerLinkActive="is-active"
              (click)="toggleMenu()"
            >
              <span class="is-bold"> Partners</span>
            </a>
          </div>
        </div>

        <!-- Search -->
        <div class="navbar-item">
          <a routerLink="/search">
            <span> Search </span>
          </a>
        </div>
        <!-- External links -->
        <div class="nav-end">
          <a
            class="navbar-item m-b-gutter"
            target="_blank"
            href="https://oecd.org"
          >
            OECD.org
          </a>
          <a
            class="navbar-item m-b-gutter"
            target="_blank"
            href="https://goingdigital.oecd.org"
          >
            Going Digital Toolkit
          </a>
          <!-- Languages | Language switcher -->
          <div class="flex align-start justify-start">
            <a
              *ngFor="let lang of environment.availableLanguages"
              class="navbar-item p-r-0"
              href="{{ lang.url }}{{ alternativeLangPath }}"
            >
              {{ lang.name }}
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
