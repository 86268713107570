<div class="container-full bg-theme">
  <div class="container">
    <div class="columns">
      <div class="column is-12 p-b-xl">
        <app-breadcrumbs
          [darkTheme]="hasDarkTheme"
          [breadcrumbLinks]="breadcrumbLinks"
        ></app-breadcrumbs>
        <h1 class="title is-1 has-text-light">
          {{ title }}
        </h1>
        <p class="has-text-light m-y-gutter">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="container-full">
  <div class="subnav bg-swamp">
    <div class="container">
      <div class="columns">
        <div class="column p-y-0">
          <div class="full-width subnav--list">
            <a
              *ngFor="let tab of tabs"
              class="subnav--item is-wips"
              [ngClass]="{
                'is-active': selectedTab === tab
              }"
              [routerLink]="tab.baseUrl"
              >{{ tab.label }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
