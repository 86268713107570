import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'

import { environment } from '../../../environments/environment'
import { appConstants } from '../../app.constants'
import { Dashboard } from '../../modules/global-ai-db/interfaces/dashboard.interface'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getMainDashboard(): Promise<Dashboard> {
    return this.list(appConstants.API_ENDPOINTS.MAIN_DASHBOARD, {
      language: environment.lang
    })
  }

  getCountryDashboard(id: string): Promise<Dashboard> {
    return this.list(appConstants.API_ENDPOINTS.COUNTRY_DASHBOARD, {
      uri:
        (appConstants.COUNTRIES_THAT_ARE_ORGANIZATIONS.includes(id)
          ? appConstants.PREFIXES.ORGANIZATION
          : appConstants.PREFIXES.COUNTRY) + id,
      language: environment.lang
    })
  }

  getCountryThemeDashboard(
    countryId: string,
    themeId: string
  ): Promise<Dashboard> {
    return this.list(appConstants.API_ENDPOINTS.COUNTRY_THEME_DASHBOARD, {
      countryUri:
        (countryId === 'EuropeanUnion'
          ? appConstants.PREFIXES.ORGANIZATION
          : appConstants.PREFIXES.COUNTRY) + countryId,
      mainThemeUri: appConstants.PREFIXES.THEME + themeId,
      language: environment.lang
    })
  }

  getTargetGroupDashboard(id: string): Promise<Dashboard> {
    return this.list(appConstants.API_ENDPOINTS.TARGET_GROUP_DASHBOARD, {
      uri: appConstants.PREFIXES.TARGET_GROUP + id,
      language: environment.lang
    })
  }

  getPolicyInstrumentDashboard(id: string): Promise<Dashboard> {
    return this.list(appConstants.API_ENDPOINTS.POLICY_INSTRUMENT_DASHBOARD, {
      uri: appConstants.PREFIXES.POLICY_INSTRUMENT + id,
      language: environment.lang
    })
  }

  list(
    resourceName: string,
    refineParams?: { [key: string]: any }
  ): Promise<any> {
    let httpParams = new HttpParams()

    if (refineParams) {
      Object.keys(refineParams).forEach((key: string) => {
        if (Array.isArray(refineParams[key])) {
          refineParams[key].forEach((value: string) => {
            httpParams = httpParams.append(key, value)
          })
        } else {
          httpParams = httpParams.set(key, refineParams[key])
        }
      })
    }

    return firstValueFrom(
      this.http.get(`${environment.apiDashboardUrl}${resourceName}`, {
        params: httpParams
      })
    )
  }

  show(
    resourceName: string,
    id: number | string,
    suffix?: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.get(
        `${environment.apiDashboardUrl}${resourceName}/${id}` +
          (suffix ? `/${suffix}` : '')
      )
    )
  }

  showFromAbsoluteUrl(fullUrl: string): Promise<any> {
    return firstValueFrom(this.http.get(fullUrl))
  }

  showFromQueryBuilder(resourceName: string): Promise<any> {
    return firstValueFrom(
      this.http.get(`${environment.queryBuilderBaseUrl}${resourceName}`, {
        responseType: 'blob',
        params: { language: environment.lang }
      })
    )
  }

  store(resourceName: string, formData: FormData): Promise<any> {
    return firstValueFrom(
      this.http.post(
        `${environment.apiDashboardUrl}${resourceName}`,
        formData,
        { responseType: 'text' }
      )
    )
  }

  update(
    resourceName: string,
    id: number | string,
    formData: FormData
  ): Promise<any> {
    return firstValueFrom(
      this.http.put(
        `${environment.apiDashboardUrl}${resourceName}/${id}`,
        formData
      )
    )
  }

  patch(
    resourceName: string,
    id: number | string,
    suffix: string,
    formData: FormData
  ): Promise<any> {
    return firstValueFrom(
      this.http.patch(
        `${environment.apiDashboardUrl}${resourceName}/${id}/${suffix}`,
        formData
      )
    )
  }

  delete(resourceName: string, id: number | string): Promise<any> {
    return firstValueFrom(
      this.http.delete(`${environment.apiDashboardUrl}${resourceName}/${id}`)
    )
  }

  static slugifyLabel(label: string): string {
    const a =
      'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b =
      'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return label
      .replace(/ \([\s\S]*?\)/g, '') // Delete what is between parentheses
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }
}
