<div class="container-full bg-theme">
  <div class="container">
    <div class="columns">
      <div class="column is-12 p-t-xxl p-b-xl">
        <h1 class="title is-1 has-text-light">
          OECD Working Party and Network of Experts on AI
        </h1>
        <p class="has-text-light m-y-gutter">
          Further to the announcement of an integrated partnership for the Global Partnership on AI (GPAI) with the
          OECD, the OECD ONE AI and the GPAI Multistakeholder Group are forming a new expert community. Stay tuned for
          further updates! <a
            href="https://www.oecd.org/en/about/programmes/global-partnership-on-artificial-intelligence.html"
            target="_blank" class="has-text-white">See here for more information</a>.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container-full">
  <div class="subnav bg-swamp">
    <div class="container">
      <div class="columns">
        <div class="column is-12 p-y-0">
          <div class="full-width subnav--list">
            <a class="subnav--item" routerLink="/network-of-experts/"
              [ngClass]="{ 'is-active': selectedNavItem === 'about' }">About</a>

            <a *ngFor="let workingGroup of workingGroups" class="subnav--item" [routerLink]="[
                '/network-of-experts',
                'working-group',
                workingGroup.id
              ]" [ngClass]="{
                'is-active': selectedNavItem === workingGroup.name
              }">{{ workingGroup.name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
