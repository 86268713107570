import { Injectable } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import * as he from 'he'
import * as moment from 'moment'

import { AIPublication } from 'src/app/modules/ai-publications/interfaces/ai-publication.interface'
import { ResourceSlug } from '../enums/wordpress/wp-resource-slug.enum'
import { Author } from '../interfaces/author.interface'
import { Discussion } from '../interfaces/discussion.interface'
import { ImageSet } from '../interfaces/image-set.interface'
import { Page } from '../interfaces/page.interface'
import { QuickLink } from '../interfaces/quick-link'
import { AIResource } from '../interfaces/resources/ai-resource.interface'
import { Area } from '../interfaces/resources/area.interface'
import { Document } from '../interfaces/resources/document.interface'
import { Event } from '../interfaces/resources/event.interface'
import { ExternalLink } from '../interfaces/resources/external-link.interface'
import { FeaturedCard } from '../interfaces/resources/featured-card.interface'
import { KeyTheme } from '../interfaces/resources/key-theme.interface'
import { Post } from '../interfaces/resources/post.interface'
import { Publication } from '../interfaces/resources/publication.interface'
import { StakeholderInitiative } from '../interfaces/resources/stakeholder-initiative.interface'
import { VisualizationTaxonomy } from '../interfaces/resources/visualization-taxonomy.interfaces'
import { Visualization } from '../interfaces/resources/visualization.interface'
import { WorkingGroup } from '../interfaces/resources/working-group.interface'
import { Video } from '../interfaces/video.interface'
import { WPAIPublication } from '../interfaces/wordpress/wp-ai-publication.interface'
import { WPAIResource } from '../interfaces/wordpress/wp-ai-resource.interface'
import { WPArea } from '../interfaces/wordpress/wp-area.interface'
import { WPDiscussion } from '../interfaces/wordpress/wp-discussion.interface'
import { WPDocument } from '../interfaces/wordpress/wp-document.interface'
import { WPEvent } from '../interfaces/wordpress/wp-event.interface'
import { WPExternalLink } from '../interfaces/wordpress/wp-external-link.interface'
import { WPFeaturedCard } from '../interfaces/wordpress/wp-featured-card.interface'
import { WPKeyTheme } from '../interfaces/wordpress/wp-key-themes.interface'
import { WPPage } from '../interfaces/wordpress/wp-page.interface'
import { WPPost } from '../interfaces/wordpress/wp-post.interface'
import { WPPublication } from '../interfaces/wordpress/wp-publication.interface'
import { WPQuickLink } from '../interfaces/wordpress/wp-quick-link'
import { WPStakeholderInitiative } from '../interfaces/wordpress/wp-stakeholder-initiative.interface'
import { WPUser } from '../interfaces/wordpress/wp-user.interface'
import { WPVideo } from '../interfaces/wordpress/wp-video.interface'
import { WPVisualization } from '../interfaces/wordpress/wp-visualization.interface'
import { StripHtmlPipe } from '../pipes/strip-html.pipe'

@Injectable({
  providedIn: 'root'
})
export class FormatResourceService {
  constructor(
    private stripHtmlPipe: StripHtmlPipe,
    private sanitizer: DomSanitizer
  ) {}

  formatPost(wpPost: WPPost): Post {
    return {
      id: wpPost.id,
      slug: wpPost.slug,
      title: this.htmlDecode(wpPost.title?.rendered),
      subtitle: wpPost.acf && wpPost.acf.subtitle ? wpPost.acf.subtitle : null,
      excerpt: this.htmlDecode(wpPost.excerpt?.rendered),
      publicationDate: wpPost.date,
      featured: wpPost.acf ? !!wpPost.acf.featured : null,
      content: wpPost.content && wpPost.content.rendered,
      metaTitle: wpPost.yoast ? wpPost.yoast.title : null,
      metaDescription: wpPost.yoast ? wpPost.yoast.metadesc : null,
      image: this.formatWPImages(wpPost),
      workingGroup: wpPost.acf
        ? this.formatWorkingGroups(wpPost.acf.working_group || [])[0]
        : null,
      documents: wpPost.acf
        ? this.formatDocuments(wpPost.acf.related_documents)
        : null,
      categories: wpPost.acf
        ? this.formatPostTaxonomy(wpPost, 'category')
        : null,
      countries: wpPost.acf
        ? this.formatPostTaxonomy(wpPost, ResourceSlug.Countries)
        : null,
      AIPrinciples: wpPost.acf
        ? this.formatPostTaxonomy(wpPost, ResourceSlug.Principles)
        : null,
      policyAreas: wpPost.acf
        ? this.formatPostTaxonomy(wpPost, ResourceSlug.PolicyAreas)
        : null,
      stakeholderTypes: wpPost.acf
        ? this.formatPostTaxonomy(wpPost, ResourceSlug.StakeholderTypes)
        : null,
      types: wpPost.acf
        ? this.formatPostTaxonomy(wpPost, ResourceSlug.ToolTypes)
        : null,
      highlightedCountryIds: wpPost.acf?.country_highlighted || [],
      tags: wpPost.acf ? this.formatPostTaxonomy(wpPost, 'post_tag') : null,
      author: wpPost._embedded?.author
        ? this.formatAuthor(wpPost._embedded.author[0])
        : null,
      coAuthorIds: wpPost.acf ? wpPost.acf.coAuthorIds || [] : null,
      link: wpPost.acf?.link
        ? this.stripHtmlPipe.transform(wpPost.acf.link)
        : null
    }
  }

  formatPostTaxonomy(
    wpPost: WPPost,
    termSlug: string
  ): { id: number; name: string }[] {
    return (
      (wpPost._embedded?.['wp:term'] &&
        wpPost._embedded['wp:term'].find(
          (termArray) => termArray.length && termArray[0].taxonomy === termSlug
        )) ||
      []
    ).map((term) => ({
      name: this.htmlDecode(term.name),
      id: term.id,
      slug: term.slug,
      code: term.acf && term.acf.code
    }))
  }

  formatAIResource(wpAiResource: WPAIResource): AIResource {
    return {
      ...this.formatPost(wpAiResource),
      isOECDResource: this.stripHtmlPipe.transform(
        wpAiResource.acf.isOECDResource
      ),
      description: this.stripHtmlPipe.transform(wpAiResource.excerpt.rendered)
    }
  }

  formatArea(wpArea: WPArea): Area {
    return {
      ...this.formatPost(wpArea),
      name: this.stripHtmlPipe.transform(wpArea.name),
      description: wpArea.description,
      parent: wpArea.parent,
      sortOrder: wpArea.acf.order
    }
  }

  formatExternalLink(wpExternalLink: WPExternalLink): ExternalLink {
    return {
      id: wpExternalLink.id,
      label: wpExternalLink.title.rendered,
      url: wpExternalLink.acf.link,
      date: wpExternalLink.date,
      type: this.formatTaxonomy(wpExternalLink, ResourceSlug.ExternalLinkType)
    }
  }

  formatDocument(wpDocument: WPDocument): Document {
    return {
      ...this.formatPost(wpDocument),
      name: this.htmlDecode(wpDocument.title.rendered),
      path: wpDocument.acf && wpDocument.acf.file && wpDocument.acf.file.url,
      date: wpDocument.date,
      description: wpDocument.acf && wpDocument.acf.description,
      workingGroupIds: wpDocument.acf.workingGroupIds
    }
  }

  formatDocuments(wpDocuments: WPDocument[]): Document[] {
    return (wpDocuments || []).map(
      (wpDocument: WPDocument): Document => ({
        ...this.formatPost(wpDocument),
        id: wpDocument.ID,
        name: wpDocument.post_title,
        slug: wpDocument.name,
        date: wpDocument.date,
        path: wpDocument.acf && wpDocument.acf.file && wpDocument.acf.file.url,
        description: wpDocument.acf ? wpDocument.acf.description : null,
        authorId: wpDocument.author
      })
    )
  }

  formatWorkingGroup(wpWorkingGroup: WPPost): WorkingGroup {
    return {
      ...this.formatPost(wpWorkingGroup),
      name: this.htmlDecode(wpWorkingGroup.title?.rendered)
    }
  }

  formatWorkingGroups(
    wpWorkingGroup: {
      ID: number
      post_title: string
    }[]
  ): WorkingGroup[] {
    return (wpWorkingGroup || []).map(
      (wpWorkingGroup: { ID: number; post_title: string }) => ({
        id: wpWorkingGroup.ID,
        name: this.htmlDecode(wpWorkingGroup.post_title)
      })
    )
  }

  formatFeaturedCard(wpFeaturedCard: WPFeaturedCard): FeaturedCard {
    return {
      ...this.formatPost(wpFeaturedCard),
      tag: wpFeaturedCard.acf?.tag,
      tagColor: wpFeaturedCard.acf?.tagColor,
      description: wpFeaturedCard.acf?.description,
      moreInfos: wpFeaturedCard.acf?.moreInfos,
      featuredOnHome: wpFeaturedCard.acf?.featuredOnHome,
      order: wpFeaturedCard.acf?.order
    }
  }

  formatQuickLink(wpQuickLink: WPQuickLink): QuickLink {
    return {
      ...this.formatPost(wpQuickLink),
      isExternalLink: wpQuickLink.acf.IsExternalLink,
      groupId: wpQuickLink['quick-link-groups'],
      group: this.formatTaxonomy(wpQuickLink, 'quick-link-groups')?.[0]
    }
  }
  formatPage(wpPage: WPPage): Page {
    return {
      ...this.formatPost(wpPage),
      text1: this.replaceLineBreaksByBRTags(wpPage.acf.text1),
      text2: this.replaceLineBreaksByBRTags(wpPage.acf.text2),
      text3: this.replaceLineBreaksByBRTags(wpPage.acf.text3),
      text4: this.replaceLineBreaksByBRTags(wpPage.acf.text4),
      text5: this.replaceLineBreaksByBRTags(wpPage.acf.text5),
      text6: this.removeAutomaticWPBrTagWhenUlLiTags(
        this.replaceLineBreaksByBRTags(wpPage.acf.text6)
      ),
      text7: this.replaceLineBreaksByBRTags(wpPage.acf.text7),
      richText1: this.sanitizer.bypassSecurityTrustHtml(wpPage.acf.richText1),
      richText2: this.sanitizer.bypassSecurityTrustHtml(wpPage.acf.richText2),
      richText3: this.sanitizer.bypassSecurityTrustHtml(wpPage.acf.richText3),
      richText4: this.sanitizer.bypassSecurityTrustHtml(wpPage.acf.richText4),
      richText5: this.sanitizer.bypassSecurityTrustHtml(wpPage.acf.richText5),
      richText6: this.sanitizer.bypassSecurityTrustHtml(wpPage.acf.richText6),
      richText7: this.sanitizer.bypassSecurityTrustHtml(wpPage.acf.richText7),
      image1: wpPage.acf.image1,
      image2: wpPage.acf.image2,
      image3: wpPage.acf.image3,
      image4: wpPage.acf.image4,
      image5: wpPage.acf.image5,
      link1: wpPage.acf.link1,
      link2: wpPage.acf.link2,
      link3: wpPage.acf.link3,
      link4: wpPage.acf.link4,
      link5: wpPage.acf.link5,
      link6: wpPage.acf.link6,
      link7: wpPage.acf.link7,
      featuredCardIds: wpPage.acf?.featuredCardIds,

      relatedPostTagIds: wpPage.acf?.relatedPostTag
    }
  }

  formatWipsKeyTheme(wpKeyTheme: WPKeyTheme): KeyTheme {
    return {
      ...this.formatPost(wpKeyTheme),
      liveNewsUrl:
        wpKeyTheme.acf && wpKeyTheme.acf.liveNewsUrl
          ? wpKeyTheme.acf.liveNewsUrl
          : '',
      moreInformation:
        wpKeyTheme.acf && wpKeyTheme.acf.moreInformation
          ? wpKeyTheme.acf.moreInformation
          : '',
      readMore:
        wpKeyTheme.acf && wpKeyTheme.acf.readMore
          ? wpKeyTheme.acf.readMore
          : '',
      relatedTag:
        wpKeyTheme.acf && wpKeyTheme.acf.relatedTag
          ? {
              id: wpKeyTheme.acf.relatedTag.term_id,
              name: wpKeyTheme.acf.relatedTag.name,
              slug: wpKeyTheme.acf.relatedTag.slug
            }
          : null,
      publications: ((wpKeyTheme.acf && wpKeyTheme.acf.publications) || []).map(
        (v: WPPost) => this.formatPublication(v)
      ),
      videos: ((wpKeyTheme.acf && wpKeyTheme.acf.videos) || []).map(
        (v: WPVideo) => this.formatVideo(v)
      ),
      featuredCardId: wpKeyTheme.acf?.featuredCardId
    }
  }

  formatPublication(wpPublication: WPPublication): Publication {
    return {
      id: wpPublication.id || wpPublication.ID,
      title: this.htmlDecode(wpPublication.title?.rendered),
      date: wpPublication.acf?.date,
      sortDate: wpPublication.acf?.sortDate,
      externalLink: wpPublication.acf?.link,
      description:
        wpPublication.excerpt &&
        this.stripHtmlPipe.transform(wpPublication.excerpt.rendered),
      category: wpPublication.acf?.category,
      company: wpPublication.acf?.company,
      logo:
        wpPublication._embedded && this.formatWPImages(wpPublication).bookCover
    }
  }

  formatAIPublication(wpAIPublication: WPAIPublication): AIPublication {
    const links: { linkAnchor: string; linkURL: string }[] = []
    const files: { fileAnchor: string; filePath: string }[] = []

    for (let i = 1; i <= 10; i++) {
      const linkAnchorKey = `link${i}Anchor`
      const linkUrlKey = `link${i}Url`
      const fileAnchorKey = `file${i}Anchor`
      const fileKey = `file${i}`

      if (
        wpAIPublication.acf[linkAnchorKey] &&
        wpAIPublication.acf[linkUrlKey]
      ) {
        links.push({
          linkAnchor: wpAIPublication.acf[linkAnchorKey]!,
          linkURL: wpAIPublication.acf[linkUrlKey]!
        })
      }

      if (wpAIPublication.acf[fileAnchorKey] && wpAIPublication.acf[fileKey]) {
        files.push({
          fileAnchor: wpAIPublication.acf[fileAnchorKey]!,
          filePath: wpAIPublication.acf[fileKey]!
        })
      }
    }
    return {
      ...this.formatPost(wpAIPublication),
      links,
      files
    }
  }

  formatStakeholderInitiative(
    wpStakeholderInitiative: WPStakeholderInitiative
  ): StakeholderInitiative {
    return {
      ...this.formatPost(wpStakeholderInitiative),
      organization: wpStakeholderInitiative.acf.organization,
      externalLink: wpStakeholderInitiative.acf.link,
      report: wpStakeholderInitiative.acf.organization,
      date: this.formatDate(wpStakeholderInitiative.acf.date)
    }
  }

  formatAuthor(wpUser: WPUser): Author {
    return {
      id: wpUser.id,
      slug: wpUser.slug,
      name: wpUser.first_name + ' ' + wpUser.last_name,
      bio: wpUser.acf?.bio,
      postCount: Number(wpUser.post_count),
      institution: wpUser.acf?.institution,
      member: wpUser.acf?.member || '',
      observer: wpUser.acf?.observer || '',
      formerMember: wpUser.acf?.formerMember || '',
      featuredFutures: wpUser.acf?.featuredFutures || '',
      featuredComputeClimate: wpUser.acf?.featuredComputeClimate || '',
      featuredIncidents: wpUser.acf?.featuredIncidents || '',
      featuredRiskAccountability: wpUser.acf?.featuredRiskAccountability || '',
      featuredHealth: wpUser.acf?.featuredHealth || '',
      featuredDataPrivacy: wpUser.acf?.featuredDataPrivacy || '',
      secretariat: wpUser.acf?.secretariat || '',
      gPAI: wpUser.acf?.gPAI || '',
      parliamentaryGroup: wpUser.acf?.parliamentaryGroup || '',
      position: wpUser.acf?.position,
      countryIds: wpUser.acf?.country,
      stakeholderTypeId: wpUser.acf?.stakeholder_type,
      image: this.formatAuthorImages(wpUser),
      workingGroups: this.formatWorkingGroups(wpUser.acf?.working_group)
    }
  }

  formatVisualization(wpVisualization: WPVisualization): Visualization {
    return {
      ...this.formatPost(wpVisualization),
      shortTitle: this.stripHtmlPipe.transform(wpVisualization.acf.shortTitle),
      homeTitle: this.stripHtmlPipe.transform(wpVisualization.acf.homeTitle),
      order: wpVisualization.acf.order || 0,
      url: wpVisualization.acf.iframeurl,
      source: this.formatTaxonomy(wpVisualization, ResourceSlug.Sources)?.[0]
        ?.id,
      groupId: wpVisualization.groups?.length
        ? wpVisualization.groups[0]
        : null,
      areaId: wpVisualization.areas?.length ? wpVisualization.areas[0] : null,
      groups: this.formatTaxonomy(wpVisualization, ResourceSlug.Groups),
      areas: this.formatTaxonomy(wpVisualization, ResourceSlug.Areas),
      isFeatured: wpVisualization.acf.isFeatured
    }
  }

  formatDiscussion(wpDiscussion: WPDiscussion): Discussion {
    return {
      ...this.formatPost(wpDiscussion),
      relatedDiscussionIds: wpDiscussion.acf?.relatedDiscussionIds,
      order: wpDiscussion.acf.order
    }
  }

  formatEvent(wpEvent: WPEvent): Event {
    return {
      ...this.formatPost(wpEvent),
      startDate: wpEvent.acf.startDate,
      endDate: wpEvent.acf.endDate,
      day: moment(wpEvent.acf.startDate).format('DD'),
      month: moment(wpEvent.acf.startDate).format('MMM'),
      launchTime: wpEvent.acf.launchTime,
      organizer: wpEvent.acf.organiser,
      isOpenToPublic: wpEvent.acf.isOpenToPublic,
      location: wpEvent.acf.location,
      description: wpEvent.acf.description,
      type: wpEvent.acf.typeOfEvent,
      timeZone: wpEvent.acf.timeZone,
      isExternal: wpEvent.acf.isExternal
    }
  }

  formatVisualizationTax(
    wpVisualizationTax: WPVisualization
  ): VisualizationTaxonomy {
    return {
      id: wpVisualizationTax.id,
      slug: this.stripHtmlPipe.transform(wpVisualizationTax.slug),
      name: this.stripHtmlPipe.transform(wpVisualizationTax.name),
      taxonomy: this.stripHtmlPipe.transform(wpVisualizationTax.taxonomy),
      sortOrder: wpVisualizationTax.acf.order
    }
  }

  formatVideo(wpVideo: WPVideo): Video {
    return {
      id: wpVideo.id || wpVideo.ID,
      publicationDate: wpVideo.date,
      name: this.htmlDecode(wpVideo.title?.rendered),
      youtubeLink: this.sanitizer.bypassSecurityTrustResourceUrl(
        wpVideo.acf?.youtube_link
      ),
      excerpt: this.htmlDecode(wpVideo.excerpt?.rendered),
      image: this.formatWPImages(wpVideo),
      duration: wpVideo.acf?.duration_video
    }
  }

  private formatAuthorWorkingGroup(wpUser: {
    acf: {
      working_group:
        | {
            ID: number
            post_title: string
          }[]
        | number
    }
  }): { id: number; name: string } {
    if (
      typeof wpUser.acf === 'number' ||
      !wpUser.acf.working_group ||
      !(wpUser.acf.working_group as any[]).length
    ) {
      return null
    }
    return {
      id: wpUser.acf.working_group[0].ID,
      name: wpUser.acf.working_group[0].post_title
    }
  }

  private formatWPImages(wpItem): ImageSet {
    const sizes: { [key: string]: { source_url: string } } =
      wpItem._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes

    if (!sizes) {
      return null
    }
    const imageSet: ImageSet = {}

    Object.keys(sizes).forEach((key: string) => {
      imageSet[key] = sizes[key].source_url
    })

    return imageSet
  }

  private formatAuthorImages(wpUser: WPUser): ImageSet {
    const sizes: { [key: string]: string | number } =
      wpUser.acf?.profile_picture?.sizes

    if (!sizes) {
      return undefined
    }

    const imageSet: ImageSet = {}
    Object.keys(sizes).forEach((key: string) => {
      if (typeof sizes[key] === 'string') {
        imageSet[key] = sizes[key] as string
      }
    })

    return imageSet
  }

  private formatTaxonomy(
    wpItem,
    termSlug: string
  ): { id: number; name: string; slug: string; description: string }[] {
    return (
      (wpItem._embedded?.['wp:term'] &&
        wpItem._embedded['wp:term'].find(
          (termArray) => termArray.length && termArray[0].taxonomy === termSlug
        )) ||
      []
    ).map((t) => ({
      name: this.htmlDecode(t.name),
      slug: t.slug,
      id: t.id,
      description: t.description
    }))
  }

  private replaceLineBreaksByBRTags(content: string): string {
    return (content || '').replace(/(?:\r\n|\r|\n)/g, '<br>')
  }

  private removeAutomaticWPBrTagWhenUlLiTags(content: string): string {
    const closingLITagToBeReplaced = new RegExp('</li><br>', 'g')
    const closingUlTagToBeReplaced = new RegExp('</ul><br>', 'g')
    const replaced = (content || '')
      .replace(/<br><li>/g, '<li>')
      .replace(closingLITagToBeReplaced, '</li>')
      .replace(/<br><ul>/g, '<ul>')
      .replace(closingUlTagToBeReplaced, '</ul>')
    return replaced
  }

  private formatDate(date: string): string {
    if (!date || !date.length) {
      return ''
    }
    return date.split('/').reverse().join('-')
  }

  private htmlDecode(html: string): string {
    return typeof html === 'string' ? he.decode(html) : ''
  }
}
