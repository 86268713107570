import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Author } from '../../../shared/interfaces/author.interface'

import { metaDataContent } from '../../../../locale/meta-data/meta-data.content.en'
import { BreadcrumbLink } from '../../../shared/interfaces/breadcrumb-link.interface'
import { Paginator } from '../../../shared/interfaces/paginator.interface'
import { Document } from '../../../shared/interfaces/resources/document.interface'
import { Post } from '../../../shared/interfaces/resources/post.interface'
import { WorkingGroup } from '../../../shared/interfaces/resources/working-group.interface'
import { StripHtmlPipe } from '../../../shared/pipes/strip-html.pipe'
import { AuthorService } from '../../../shared/services/author.service'
import { BreadcrumbService } from '../../../shared/services/breadcrumb.service'
import { DocumentService } from '../../../shared/services/document.service'
import { MetaService } from '../../../shared/services/meta.service'
import { PostService } from '../../../shared/services/post.service'
import { WorkingGroupService } from '../../../shared/services/working-group.service'

@Component({
  selector: 'app-working-group-detail',
  templateUrl: './working-group-detail.component.html',
  styleUrls: ['./working-group-detail.component.scss']
})
export class WorkingGroupDetailComponent implements OnInit {
  workingGroup: WorkingGroup
  paginator: Paginator<Author>
  authors: Author[]

  breadcrumbLinks: BreadcrumbLink[]
  loadingMembers: boolean
  loadingPosts: boolean

  selectedParams: {
    role?: string
    workingGroupId?: string
    terms?: string
    countryId?: string
    stakeholderTypeId?: string
    page?: string
  }

  currentPath: string

  constructor(
    private activatedRoute: ActivatedRoute,
    private workingGroupService: WorkingGroupService,
    private documentService: DocumentService,
    private breadcrumbService: BreadcrumbService,
    private metaService: MetaService,
    private stripHtmlPipe: StripHtmlPipe,
    private router: Router,
    private authorService: AuthorService,
    private postService: PostService
  ) {
    metaService.setTags(metaDataContent.networkOfExperts)
    this.breadcrumbLinks =
      breadcrumbService.getBreadCrumbLinks('networkOfExperts')
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      const workingGroupId = params.id

      this.workingGroupService
        .show(workingGroupId)
        .then((workingGroupRes: WorkingGroup) => {
          this.workingGroup = workingGroupRes
          this.currentPath = `/network-of-experts/working-group/${this.workingGroup.id}`

          this.documentService
            .list({ workingGroupIds: workingGroupId })
            .then((documentRes: Document[]) => {
              if (documentRes.length) {
                this.workingGroup.documents = documentRes
              }
            })
          this.postService
            .listUnpaginated({ workingGroupId: workingGroupId })
            .then((postRes: Post[]) => {
              if (postRes.length) {
                this.workingGroup.posts = postRes
              }
            })
          ;(
            this.authorService.list({
              workingGroup: workingGroupId
            }) as Promise<Author[]>
          ).then((authorRes: Author[]) => {
            this.loadingMembers = false
            this.authors = authorRes
          })

          this.activatedRoute.queryParams.subscribe(
            () => {
              // Meta and breadcrumbs.
              this.metaService.setTags({
                title: workingGroupRes.name,
                description: this.stripHtmlPipe.transform(
                  workingGroupRes.excerpt
                ),
                path: this.currentPath,
                absoluteOgImage: workingGroupRes.image
                  ? workingGroupRes.image.socialCard
                  : null
              })
              this.breadcrumbLinks = this.breadcrumbService.getBreadCrumbLinks(
                'networkOfExperts',
                [
                  {
                    label: workingGroupRes.name
                  }
                ]
              )
            },

            () => {
              this.router.navigate(['/404'])
            }
          )
        })
    })
  }

  goToPage(pageNumber: number) {
    const queryParams: Params = { page: pageNumber.toString() }
    this.router.navigate([this.currentPath], {
      queryParams,
      queryParamsHandling: 'merge'
    })
  }
}
