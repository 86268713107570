import { Component, Input, OnChanges } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'

import { CommonModule } from '@angular/common'
import { Area } from '../../interfaces/resources/area.interface'
import { VisualizationTaxonomy } from '../../interfaces/resources/visualization-taxonomy.interfaces'
import { Visualization } from '../../interfaces/resources/visualization.interface'
import { VisualizationService } from '../../services/visualization.service'

@Component({
  selector: 'app-data-visualization-panel',
  templateUrl: './data-visualization-panel.component.html',
  styleUrls: ['./data-visualization-panel.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class DataVisualizationPanelComponent implements OnChanges {
  @Input() area: Area
  @Input() selectedVisualizationSlug: string
  @Input() isSourcesExtendable = false

  visualizations: Visualization[]
  sources: VisualizationTaxonomy[]
  groups: VisualizationTaxonomy[]

  selectedVisualization: Visualization
  firstVisualization: Visualization

  extendedSource: string
  extendedGroup: string

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private visualizationService: VisualizationService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnChanges() {
    this.visualizations = await this.visualizationService.listByAreas({
      areaId: this.area.id
    })

    const sourceIds = this.visualizations
      .map((v: Visualization) => v.source)
      .filter((v) => !!v)

    if (sourceIds.length) {
      this.sources = await this.visualizationService.listSources({
        ids: sourceIds
      })
    } else {
      this.sources = []
    }

    const groupIds = this.visualizations
      .map((v: Visualization) => v.groupId)
      .filter((v) => !!v)

    if (groupIds.length) {
      this.groups = await this.visualizationService.listGroups({
        ids: groupIds
      })
    } else {
      this.groups = []
    }

    // Add related visualizations to sources & group visualization without source
    this.sources.sort((a, b) => {
      return b.sortOrder - a.sortOrder
    })

    this.sources.forEach((source: VisualizationTaxonomy) => {
      source.visualizations = this.visualizations.filter(
        (viz: Visualization) => viz.source === source.id
      )
    })
    this.sources.push({
      sortOrder: 0,
      name: 'noSource',
      slug: 'noSource',
      visualizations: this.visualizations.filter(
        (viz: Visualization) => !viz.source
      )
    })

    // Add groups between sources and visualizations.
    this.sources.forEach((source: VisualizationTaxonomy) => {
      source.groups = [
        {
          name: 'noGroup',
          slug: 'noGroup',
          visualizations: []
        }
      ]

      source.visualizations.forEach((viz: Visualization) => {
        // Visualizations without a Group go in the "noGroup" Group.
        const sourceGroup: VisualizationTaxonomy = viz.groupId
          ? source.groups.find((group) => group.id === viz.groupId)
          : source.groups.find((g) => g.name === 'noGroup')

        if (sourceGroup) {
          sourceGroup.visualizations.push(viz)
        } else {
          const newGroup: VisualizationTaxonomy = this.groups.find(
            (group) => group.id === viz.groupId
          )
          source.groups.push({
            id: newGroup.id,
            slug: `${source.slug}-${newGroup.slug}`,
            name: newGroup.name,
            visualizations: [viz]
          })
        }
      })

      // Remove original structure as we are displaying Visualizations through Groups only.
      delete source.visualizations

      // Sort groups alphabetically.
      source.groups.sort((a, b) => (a.slug > b.slug ? 1 : -1))
    })

    // Select visualization.
    let visualizationToShow: Visualization
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (
        queryParams.selectedVisualization !== this.selectedVisualizationSlug
      ) {
        this.selectedVisualizationSlug = queryParams.selectedVisualization
      }
    })

    if (this.selectedVisualizationSlug) {
      visualizationToShow = this.findVisualization(
        this.sources,
        this.selectedVisualizationSlug
      )
    } else {
      const firstVisualization: Visualization = this.getFirstVisualization(
        this.sources
      )

      if (firstVisualization) {
        this.selectedVisualizationSlug = this.getFirstVisualization(
          this.sources
        ).slug
        visualizationToShow = this.findVisualization(
          this.sources,
          this.selectedVisualizationSlug
        )
      }
    }
    if (visualizationToShow) {
      this.showVisualization(visualizationToShow)
    }
  }

  getFirstVisualization(sources: VisualizationTaxonomy[]): Visualization {
    let firstVisualization: Visualization

    sources.forEach((source: VisualizationTaxonomy) => {
      source.groups.forEach((group: VisualizationTaxonomy) => {
        if (group.visualizations.length && !firstVisualization) {
          firstVisualization = group.visualizations[0]
        }
      })
    })
    return firstVisualization
  }

  findVisualization(
    sources: VisualizationTaxonomy[],
    slug: string
  ): Visualization {
    let foundVisualization: Visualization

    sources.forEach((source: VisualizationTaxonomy) => {
      source.groups.forEach((group: VisualizationTaxonomy) => {
        group.visualizations.forEach((viz: Visualization) => {
          if (viz.slug === slug) {
            foundVisualization = viz
          }
        })
      })
    })
    return foundVisualization
  }

  toggleExtendSource(slug: string): void {
    if (this.extendedSource === slug) {
      delete this.extendedSource
    } else {
      this.extendedSource = slug
    }
  }
  toggleExtendGroup(slug: string): void {
    if (this.extendedGroup === slug) {
      delete this.extendedGroup
    } else {
      this.extendedGroup = slug
    }
  }

  showVisualization(visualization: Visualization): void {
    this.selectedVisualization = {
      title: visualization.title,
      shortTitle: visualization.shortTitle,
      slug: visualization.slug,
      iframeurl: this.sanitizer.bypassSecurityTrustResourceUrl(
        visualization.url
      )
    }
    // Toggle parent accordions.
    this.sources.forEach((source: VisualizationTaxonomy) => {
      source.groups.forEach((group: VisualizationTaxonomy) => {
        group.visualizations.forEach((viz: Visualization) => {
          if (viz.slug === this.selectedVisualization.slug) {
            this.toggleExtendSource(source.slug)
            this.toggleExtendGroup(group.slug)
          }
        })
      })
    })
  }

  goToVisualization(slug: string): void {
    this.router.navigate([this.router.url.split('?')[0]], {
      queryParams: {
        selectedArea: this.area.slug,
        selectedVisualization: slug
      }
    })
  }
}
