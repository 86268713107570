import { environment } from '../../environments/environment'

export const metaDataContent = {
  defaultMeta: {
    title: 'OECD AI Policy Observatory',
    description:
      'OECD.AI helps countries and shape trustworthy AI with the OECD AI Principles. It gives access to 900+ national AI policies and initiatives, live data about AI and a blog about AI policy.',
    path: '',
    absoluteOgImage: environment.baseUrl + 'assets/images/default-og-ai.png'
  },

  home: {
    title: 'The OECD Artificial Intelligence Policy Observatory',
    description:
      'OECD.AI helps countries and shape trustworthy AI with the OECD AI Principles. It gives access to 900+ national AI policies and initiatives, live data about AI and a blog about AI policy.',
    path: '',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-logo-neural-network.png'
  },

  principleList: {
    title: 'The OECD Artificial Intelligence (AI) Principles',
    description:
      'The OECD AI Principles promote innovative and trustworthy AI, respect for human rights and democratic values. Adopted in May 2019, they are the first intergovernmental standard for AI.',
    path: 'ai-principles',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-logo-neural-network.png'
  },

  OECDMetricsAndMethods: {
    title: 'OECD methods & metrics for trustworthy AI',
    description:
      'The OECD is working on methodologies to quantify AI developments. This section presents official OECD publications and indicators related to measuring AI.',
    path: 'oecd-metrics-and-methods',
    absoluteOgImage: environment.baseUrl + 'assets/images/default-og-ai.png'
  },

  liveDataFromPartners: {
    title: 'Live data from OECD.AI ',
    description:
      'Consult live data from OECD.AI partners help understand trends in AI in research, employment, development and other domains.',
    path: 'data',
    absoluteOgImage: environment.baseUrl + 'assets/images/default-og-ai.png'
  },

  globalAIDB: {
    title: `OECD's live repository of  AI strategies & policies`,
    description:
      'A live repository of AI policy initiatives from over 70 countries, territories and the EU. Each country/territory has a dashboard including policies and data.',
    path: 'dashboards',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-country-dashboards.png'
  },

  networkOfExperts: {
    title: 'OECD.AI Network of experts (ONE AI)',
    description:
      'The OECD.AI network of experts provides policy, technical and business input for OECD analytical work and identifies possible trends.',
    path: 'network-of-experts',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-logo-neural-network.png'
  },

  survey: {
    title:
      'Survey : Practical use cases for implementation of the OECD AI Principles',
    description:
      'Please share your experience with us through this short survey on practical approaches and good practices to help AI actors and decision-makers to implement trustworthy AI systems.',
    path: 'survey',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-logo-neural-network.png'
  },
  classificationSurvey: {
    title: 'Survey for the classification of AI Systems',
    description:
      'Please share your experience with us through this short survey on the classification of AI systems.',
    path: 'survey',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-logo-neural-network.png'
  },

  resources: {
    title:
      'OECD AI’s list of resources for learning about Artificial Intelligence and what it can do',
    description:
      'OECD.AI provides this list of resources to help policy makers and other actors to learn more about Artificial Intelligence, what it can do and how to work with it.',
    path: 'resources',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-logo-neural-network.png'
  },

  FAQ: {
    title: 'OECD.AI FAQ',
    description:
      'This section provides answers to common questions about OECD.AI.',
    path: 'faq',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-logo-neural-network.png'
  },

  wips: {
    title: 'The OECD.AI programme on Work, Innovation, Productivity and Skills',
    description:
      'The OECD programme on AI in Work, Innovation, Productivity and Skills (AI-WIPS) - with the support of Germany - analyses the impact of AI on the labour market, skills and social policy.',
    path: 'work-innovation-productivity-skills'
  },

  policyInitiativeList: {
    title: 'Policies',
    path: 'policies'
  },

  search: {
    title: 'Search',
    path: 'search',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-logo-neural-network.png'
  },

  videos: {
    title: 'Videos',
    description: 'OECD.AI Videos on a page.',
    path: '',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-logo-neural-network.png'
  },

  // Blog.
  blogIndex: {
    title: 'The AI Wonk',
    path: 'wonk',
    description:
      'Not all intelligence is artificial. Keep yours real with the AI Wonk blog. AI stakeholders worldwide discuss current issues in AI policy, technology, law, development and how it effects everyday lives.',
    absoluteOgImage: environment.baseUrl + 'assets/images/oecd-ai-wonk.png'
  },

  authorList: {
    title: 'AI Wonk community',
    path: 'community',
    description:
      'Our work relies upon the contributions of a global and diverse group of AI professionals. The OECD.AI Network of Experts advises the OECD and contributes to its work, but so do many more of our partners whom you can find here.'
  },

  // AI Tool catalogue (EN only).
  toolCatalogOverview: {
    title: 'Catalogue of Tools & Metrics for Trustworthy AI',
    description:
      'An interactive collection of the latest tools and resources to help AI actors be accountable and ensure that AI systems and applications respect human rights and are fair, transparent, explainable, robust, secure and safe.',
    path: 'catalogue',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/catalogue-og-image.png'
  },
  toolCatalogToolList: {
    title: 'Tools for Trustworthy AI',
    path: 'catalogue/tools',
    description:
      'The OECD Catalogue of Tools & Metrics is for trustworthy AI systems and applications that respect human rights and are fair, transparent, explainable, robust, secure and safe. ',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/catalogue-og-image.png'
  },
  toolCatalogUseCaseList: {
    title: 'Use Cases from Tools or Trustworthy AI',
    path: 'catalogue/tool-use-cases',
    description:
      'These use cases share experiences and advice about AI tools in the OECD Catalogue of Tools & Metrics. Have you used a tool from the catalogue? Please share your experience.',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/catalogue-og-image.png'
  },
  toolCatalogMetricList: {
    title: 'Metrics for Trustworthy AI',
    path: 'catalogue/metrics',
    description:
      'These use cases share experiences and advice about AI tools in the OECD Catalogue of Tools & Metrics. Have you used a tool from the catalogue? Please share your experience.',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/catalogue-og-image.png'
  },
  toolCatalogMetricUseCaseList: {
    title: 'Metric Use Cases from Tools or Trustworthy AI',
    path: 'catalogue/metric-use-cases',
    description:
      'These use cases share experiences and advice about AI tools in the OECD Catalogue of Tools & Metrics. Have you used a tool from the catalogue? Please share your experience.',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/catalogue-og-image.png'
  },
  toolCatalogToolSubmit: {
    title:
      'Submit an AI Tool to the Catalogue of Tools & Metrics for Trustworthy AI',
    path: 'catalogue/tools/submit',
    description:
      'These use cases share experiences and advice about AI tools in the OECD Catalogue of Tools & Metrics. Have you used a tool from the catalogue? Please share your experience.',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/catalogue-og-image.png'
  },
  toolCatalogUseCaseSubmit: {
    title:
      'Submit a Use Case to the Catalogue of Tools & Metrics for Trustworthy AI',
    path: 'catalogue/tool-use-cases/submit',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/catalogue-og-image.png'
  },
  toolCatalogMetricSubmit: {
    title:
      'Submit a metric to the Catalogue of Tools & Metrics for Trustworthy AI',
    path: 'catalogue/tools/submit',
    description:
      'These use cases share experiences and advice about AI tools in the OECD Catalogue of Tools & Metrics. Have you used a tool from the catalogue? Please share your experience.',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/catalogue-og-image.png'
  },
  toolCatalogMetricUseCaseSubmit: {
    title:
      'Submit a metric use case to the Catalogue of Tools & Metrics for Trustworthy AI',
    path: 'catalogue/tool-use-cases/submit',
    description:
      'These use cases share experiences and advice about AI tools in the OECD Catalogue of Tools & Metrics. Have you used a tool from the catalogue? Please share your experience.',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/catalogue-og-image.png'
  },
  toolCatalogAbout: {
    title: 'About the Catalogue of Tools & Metrics for Trustworthy AI',
    path: 'catalogue/about',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/catalogue-og-image.png'
  },

  // AI Futures.
  futuresOverview: {
    title: 'AI Futures',
    path: 'network-of-experts/ai-futures'
  },
  futuresDiscussions: {
    title: 'AI Futures Discussions',
    path: 'network-of-experts/ai-futures/dicsussions'
  },
  futuresExperts: {
    title: 'AI Futures Experts',
    path: 'network-of-experts/ai-futures/experts'
  },
  futuresResources: {
    title: 'AI Futures Resources',
    path: 'network-of-experts/ai-futures/resources'
  },

  // Incidents.
  incidentsList: {
    title:
      'AIM: The OECD AI Incidents Monitor, an evidence base for trustworthy AI',
    path: 'incidents',
    description:
      'The OECD AI Incidents Monitor (AIM) documents the negative outcomes of AI, AI incidents. It gives policymakers an evidence base to create policies for safer AI.',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/oecd-ai-incidents-monitor-aim.png'
  },

  // Other.
  error404: {
    title: 'Error 404 : Page not found - OECD AI Policy Observatory',
    description: 'We could not find the page you are looking for.',
    path: '404',
    absoluteOgImage:
      environment.baseUrl + 'assets/images/error-404-og-image.png'
  }
}
