import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { Router, RouterLink } from '@angular/router'
import { BreadcrumbLink } from '../../interfaces/breadcrumb-link.interface'
import { HeadTab } from '../../interfaces/head-tab.interface'
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component'

@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  standalone: true,
  styleUrls: ['./head.component.scss'],
  imports: [CommonModule, RouterLink, BreadcrumbsComponent]
})
export class HeadComponent implements OnInit {
  @Input({ required: true }) breadcrumbLinks: BreadcrumbLink[]
  @Input({ required: true }) title: string
  @Input({ required: true }) description: string
  @Input({ required: true }) tabs: HeadTab[]

  @Input({ required: false }) hasDarkTheme: boolean

  selectedTab: HeadTab

  constructor(private router: Router) {}

  ngOnInit() {
    this.hasDarkTheme = this.hasDarkTheme ? this.hasDarkTheme : true
    this.selectedTab = this.tabs.find((tab) => tab.baseUrl === this.router.url)
  }
}
