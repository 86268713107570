<!-- Main navbar -->
<div id="header" class="container-full bg-primary" (mouseleave)="hideDropdown()">
  <div class="container">
    <nav class="navbar" role="navigation" aria-label="dropdown navigation" id="main-navbar">
      <div class="nav-left">
        <a class="navbar__logo" routerLink="/" tabindex="0">
          <img loading="lazy" src="./assets/images/oecd-ai-logo.svg" alt="OECD AI Policy Observatory" width="246" />
        </a>
      </div>
      <div class="nav-right">
        <div class="nav-top">
          <!-- External links -->
          <a i18n="@@headerOECD.ORGBacklink" class="navbar-item" tabindex="0" target="_blank" href="https://oecd.org">
            OECD.org
          </a>
          <a i18n="@@headerToolkitBacklink" class="navbar-item" tabindex="0" target="_blank"
            href="https://goingdigital.oecd.org">
            Going Digital Toolkit
          </a>
          <!-- Language switcher -->
          <div class="has-dropdown navbar-item language-switcher">
            <div class="navbar-link m-r-0 is-uppercase" (click)="showAvailableLanguages = !showAvailableLanguages"
              (keydown)="showAvailableLanguages = !showAvailableLanguages" tabindex="0" id="language-switcher-button">
              {{ environment.lang }}
            </div>
            <ul *ngIf="showAvailableLanguages" class="languages-list absolute no-lst">
              <li *ngFor="let lang of environment.availableLanguages">
                <a class="navbar-item" tabindex="0" href="{{ lang.url }}{{ alternativeLangPath }}">
                  {{ lang.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="nav-main">
          <!-- Blog -->
          <a class="navbar-item" tabindex="0" routerLink="/wonk" [ngClass]="{
              'is-active': activeTab === 'blog'
            }">
            <span i18n="@@headerBlogNavbarTitle"> Blog</span>
          </a>

          <!-- Live data -->
          <a class="navbar-item" routerLink="/data" tabindex="0" routerLinkActive="is-active" [ngClass]="{
              'is-active': activeTab === 'liveData'
            }">
            <span i18n="@@headerTrendsNavbarTitle">Live data</span>
          </a>

          <!-- Countries -->
          <a class="navbar-item" routerLink="/dashboards/overview" tabindex="0" [ngClass]="{
              'is-active': activeTab === 'countries'
            }">
            <span i18n="@@headerCountriesInitiativesNavbarTitle">
              Countries
            </span>
          </a>

          <!-- Priority issues -->
          <div class="navbar-item has-dropdown is-active" (click)="showDropdown('showPriorityIssuesDropdown')"
            (keydown.enter)="showDropdown('showPriorityIssuesDropdown')"
            (focus)="showDropdown('showPriorityIssuesDropdown')" tabindex="0" [ngClass]="{
              'is-active': activeTab === 'priorityIssues'
            }">
            <a class="navbar-link">
              <span> Priority issues </span>
            </a>
          </div>

          <!-- Tools -->
          <div class="navbar-item has-dropdown is-active" (click)="showDropdown('showToolsDropdown')"
            (keydown.enter)="showDropdown('showToolsDropdown')" (focus)="showDropdown('showToolsDropdown')" tabindex="0"
            [ngClass]="{
              'is-active': activeTab === 'tools'
            }">
            <a class="navbar-link">
              <span>Tools</span>
            </a>
          </div>
          <!-- Resources -->
          <div class="navbar-item has-dropdown is-active" (click)="showDropdown('showResourcesDropdown')"
            (keydown.enter)="showDropdown('showResourcesDropdown')" (focus)="showDropdown('showResourcesDropdown')"
            tabindex="0" [ngClass]="{
              'is-active': activeTab === 'resources'
            }">
            <a class="navbar-link">
              <span>Resources</span>
            </a>
          </div>

          <!-- About -->
          <div class="navbar-item has-dropdown" tabindex="0" (click)="showDropdown('showAboutDropdown')"
            (keydown.enter)="showDropdown('showAboutDropdown')" (focus)="showDropdown('showAboutDropdown')"
            [ngClass]="{ 'is-active': activeTab === 'about' }">
            <a class="navbar-link">
              <span>About</span>
            </a>
          </div>

          <!-- Search-->
          <a class="navbar-item p-l-md" (click)="toggleSearch()" (keydown)="toggleSearch()" tabindex="0">
            <span>
              <i class="icon icon-Search p-t-sm"></i>
            </span>
          </a>
          <app-search-bar [ngClass]="{
              'is-active': showSearch
            }" [showSearch]="showSearch"></app-search-bar>
        </div>
      </div>
    </nav>
  </div>

  <!-- priorityIssuesDropdown  -->
  <div class="navbar-dropdown navbar-dropdown--large" [ngClass]="{
      'is-active': activeDropdown === 'showPriorityIssuesDropdown'
    }">
    <div class="container m-b-xxl">
      <div class="columns is-multiline">
        <div class="column is-20-percent">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/site/risk-accountability"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/portals/tools-md.png" style="
                  background-image: url('./assets/images/portals/tools-small.png');
                " alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>AI Risk & Accountability</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              AI has risks and all actors must be accountable.
            </p>
          </a>
        </div>
        <div class="column is-20-percent">
          <a class="nav-item-link has-text-light ghost" routerLink="/site/incidents" tabindex="0"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/portals/manage-incidents-md.png" style="
                  background-image: url('./assets/images/portals/manage-incidents-small.png');
                " alt="manage incidents" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>AI Incidents</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              To manage risks, governments must track and understand AI
              incidents and hazards.
            </p>
          </a>
        </div>
        <div class="column is-20-percent">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/site/compute-climate"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/portals/carbon-md.png" style="
                  background-image: url('./assets/images/portals/carbon-small.png');
                " alt="AI carbon" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>AI Compute & Climate</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              AI computing capacities and their environmental impact.
            </p>
          </a>
        </div>
        <div class="column is-20-percent">
          <a class="nav-item-link has-text-light ghost" routerLink="/site/ai-futures" tabindex="0"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/portals/foresight-md.png" style="
                  background-image: url('./assets/images/portals/foresight-small.png');
                " alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>AI Futures</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">AI’s potential futures.</p>
          </a>
        </div>
        <div class="column is-20-percent">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/site/data-privacy"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/portals/ai-data-privacy-md.png" style="
                  background-image: url('./assets/images/portals/ai-data-privacy-small.png');
                " alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>AI, Data & Privacy</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              Data and privacy are primary policy issues for AI.
            </p>
          </a>
        </div>
        <div class="column is-3">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/site/health"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/portals/ai-health-md.jpg" style="
                  background-image: url('./assets/images/portals/ai-health-small.jpg');
                " alt="AI & Health" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>AI & Health</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              AI can help health systems overcome their most urgent challenges.
            </p>
          </a>
        </div>
        <div class="column is-3">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/genai" routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/item-genai.png" style="
                  background-image: url('./assets/images/item-genai-small.png');
                " alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>Generative AI</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              Managing the risks and benefits of generative AI.
            </p>
          </a>
        </div>
        <div class="column is-3">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/work-innovation-productivity-skills"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/wips-md.png"
                style="background-image: url('./assets/images/wips-small.png')" alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>WIPS</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              Programme on Work, Innovation, Productivity and Skills in AI.
            </p>
          </a>
        </div>
        <div class="column is-3">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/site/ai-index"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/index-md.png"
                style="background-image: url('./assets/images/index-small.png')" alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>AI Index</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              The OECD AI will be a synthetic measurement framework on
              Trustworthy Artificial Intelligence (AI)
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- toolsDropdoiwn  -->
  <div class="navbar-dropdown navbar-dropdown--large" [ngClass]="{
      'is-active': activeDropdown === 'showToolsDropdown'
    }">
    <div class="container m-b-xxl">
      <div class="columns">
        <div class="column is-3 is-offset-3">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/catalogue/overview"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/catalogue-og-image-md.png" style="
                  background-image: url('./assets/images/catalogue-og-image-small.png');
                " alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>Catalogue Tools & Metrics</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              Explore tools & metrics to build and deploy AI systems that are
              trustworthy.
            </p>
          </a>
        </div>
        <div class="column is-3">
          <a class="nav-item-link has-text-light ghost" routerLink="/incidents" tabindex="0"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/item-aim.png" lazyload alt="" style="
                  background-image: url('./assets/images/item-aim-small.png');
                " />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>AI Incidents Monitor</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              Gain valuable insights on global AI incidents.
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- resourcesDropdown  -->
  <div class="navbar-dropdown navbar-dropdown--large" [ngClass]="{
      'is-active': activeDropdown === 'showResourcesDropdown'
    }">
    <div class="container m-b-xxl">
      <div class="columns">
        <div class="column">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/ai-principles"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/principles-md.png" style="
                  background-image: url('./assets/images/principles-small.png');
                " alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>OECD AI Principles</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              The first IGO standard to promote innovative and trustworthy AI
            </p>
          </a>
        </div>
        <div class="column">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/policy-areas"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/policy-md.png" style="
                  background-image: url('./assets/images/policy-small.png');
                " alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>Policy areas</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              Browse OECD work related to AI across policy areas.
            </p>
          </a>
        </div>
        <div class="column">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/ai-publications"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/publications-md.png" style="
                  background-image: url('./assets/images/publications-small.png');
                " alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>Publications</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              Find OECD publications related to AI.
            </p>
          </a>
        </div>
        <div class="column">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/videos" routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/video-md.png"
                style="background-image: url('./assets/images/video-small.png')" alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>Videos</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              Watch videos about AI policy the issues that matter most.
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- aboutDropdown  -->
  <div class="navbar-dropdown navbar-dropdown--large" [ngClass]="{
      'is-active': activeDropdown === 'showAboutDropdown'
    }">
    <div class="container m-b-xxl">
      <div class="columns">
        <div class="column">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/about/the-context"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/item-context.png" alt="" style="
                  background-image: url('./assets/images/item-context-small.png');
                " />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>Context</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              AI is already a crucial part of most people’s daily routines.
            </p>
          </a>
        </div>
        <div class="column">
          <a class="nav-item-link has-text-light ghost" routerLink="/about/what-we-do" tabindex="0"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/item-what-we-do.png" style="
                  background-image: url('./assets/images/item-what-we-do-small.png');
                " alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>What we do</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              Countries and stakeholder groups join forces to shape trustworthy
              AI.
            </p>
          </a>
        </div>
        <div class="column">
          <a class="nav-item-link has-text-light ghost" routerLink="/network-of-experts" tabindex="0"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/default-og-ai.png" style="
                  background-image: url('./assets/images/default-og-ai-small.png');
                " alt="" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>Network of Experts</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              Experts from around the world advise the OECD and contribute to
              its work.
            </p>
          </a>
        </div>
        <div class="column">
          <a class="nav-item-link has-text-light ghost" tabindex="0" routerLink="/partners"
            routerLinkActive="is-active">
            <figure class="is-fullwidth nav-item-media is-default">
              <img loading="lazy" class="is-inline-block" src="./assets/images/partners-md.png" style="
                  background-image: url('./assets/images/partners-small.png');
                " alt="OECD.AI Partners" />
            </figure>
            <p class="is-size-4 mb-2 mt-5 pt-1">
              <span class="is-fullwidth">
                <span class="icon-text">
                  <span class="icon">
                    <i class="fas fa-chevron-right is-size-6 has-text-success"></i>
                  </span>
                </span>
                <span>Partners</span>
              </span>
            </p>
            <p class="content is-small is-sans my-0">
              OECD.AI works closely with many partners.
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
