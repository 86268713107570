<div
  class="card card--post"
  [ngClass]="{
    'layout-list mb-5': layout === PostLayout.List,
    'layout-regular': layout === PostLayout.Regular
  }"
>
  <div class="card--post__media">
    <a [routerLink]="['/wonk', post.slug]" class="ghost">
      <figure *ngIf="post.image">
        <img [src]="post.image.landscapeSmall" [alt]="post.title" />

        <img
          src="./assets/images/blog-default.png"
          [alt]="post.title"
          *ngIf="!post.image"
        />
      </figure>
    </a>
  </div>
  <div class="card--post__content">
    <div class="flex align-center space-between m-b-xs m-t-gutter">
      <span
        class="has-text-link small-meta"
        *ngIf="post.stakeholderTypes && post.stakeholderTypes.length"
        >{{ post.stakeholderTypes[0].name }}</span
      >
      <div class="control is-inline-flex ml-2" *ngIf="post.isHighlighted">
        <span class="tag is-yellow-light has-text-primary">Featured</span>
      </div>
    </div>
    <a [routerLink]="['/wonk', post.slug]" class="ghost">
      <h2 class="title is-2 m-b-md" *ngIf="layout === PostLayout.Large">
        {{ post.title }}
      </h2>
      <h4
        class="title is-4 m-b-sm"
        *ngIf="
          layout === PostLayout.List ||
          layout === PostLayout.Regular ||
          layout === PostLayout.Minimal
        "
      >
        {{ post.title }}
      </h4>
    </a>
    <p
      class="excerpt m-y-md"
      *ngIf="layout === PostLayout.Large || layout === PostLayout.Regular"
    >
      <a [routerLink]="['/wonk', post.slug]" class="ghost"> {{ excerpt }}</a>
    </p>

    <p class="small-meta" *ngIf="layout !== PostLayout.Minimal">
      <span>{{ post.publicationDate | date : 'LLLL d, yyyy' }}</span>
      —
      <img
        src="./assets/images/clock-small.svg"
        style="position: relative; top: 3px"
        width="16"
        class="m-l-xs"
        alt="clock"
      />
      <span class="m-x-xs">{{ minRead }} min read</span>
    </p>
  </div>
</div>
