<section>
  <app-working-group-head [selectedNavItem]="'about'"></app-working-group-head>

  <div class="container m-t-rel">
    <app-breadcrumbs [breadcrumbLinks]="breadcrumbLinks"></app-breadcrumbs>
  </div>

  <!-- About -->
  <div class="container m-b-xxl">
    <div class="columns m-b-xl">
      <div class="column">
        <div [innerHTML]="aboutContent"></div>
      </div>
    </div>
  </div>
</section>
