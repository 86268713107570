import { NgClass, NgFor } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'

import { WorkingGroup } from '../../../../shared/interfaces/resources/working-group.interface'
import { WorkingGroupService } from '../../../../shared/services/working-group.service'

@Component({
  selector: 'app-working-group-head',
  templateUrl: './working-group-head.component.html',
  styleUrls: ['./working-group-head.component.scss'],
  standalone: true,
  imports: [NgClass, NgFor, RouterLink]
})
export class WorkingGroupHeadComponent implements OnInit {
  @Input() selectedNavItem = 'about'
  workingGroups: WorkingGroup[]

  constructor(private workingGroupService: WorkingGroupService) {}

  ngOnInit(): void {
    this.workingGroupService.list().then((workingGroupRes: WorkingGroup[]) => {
      this.workingGroups = workingGroupRes
    })
  }
}
