import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AboutComponent } from './pages/about/about.component'
import { ClassificationSurveyComponent } from './pages/classification-survey/classification-survey.component'
import { ContactComponent } from './pages/contact/contact.component'
import { FaqComponent } from './pages/faq/faq.component'
import { HomeComponent } from './pages/home/home.component'
import { LiveDataFromPartnersComponent } from './pages/live-data-from-partners/live-data-from-partners.component'
import { NetworksOfExpertsComponent } from './pages/networks-of-experts/networks-of-experts.component'
import { OecdMetricsAndMethodsComponent } from './pages/oecd-metrics-and-methods/oecd-metrics-and-methods.component'
import { ParticipantListComponent } from './pages/participant-list/participant-list.component'
import { PartnersComponent } from './pages/partners/partners.component'
import { ResourcesOnAiComponent } from './pages/resources-on-ai/resources-on-ai.component'
import { SearchComponent } from './pages/search/search.component'
import { SurveyComponent } from './pages/survey/survey.component'
import { TrendsAndDataComponent } from './pages/trends-and-data/trends-and-data.component'
import { PageDetailComponent } from './resources/page/page-detail/page-detail.component'

import { AiIndexComponent } from './pages/ai-index/ai-index.component'
import { Error404Component } from './pages/error404/error404.component'
import { VideoListComponent } from './resources/video/video-list/video-list.component'
import { WorkingGroupDetailComponent } from './resources/working-group/working-group-detail/working-group-detail.component'
import { AuthorDetailComponent } from './shared/resources/author/author-detail/author-detail.component'
import { AuthorListComponent } from './shared/resources/author/author-list/author-list.component'

const routes: Routes = [
  // Lazy loading modules.
  {
    path: 'catalogue',
    loadChildren: () =>
      import('./modules/tools-catalogue/tools-catalogue.module').then(
        (m) => m.ToolsCatalogueModule
      )
  },
  {
    path: 'dashboards',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      )
  },
  {
    path: 'genai',
    loadChildren: () =>
      import('./modules/generative-ai/generative-ai.module').then(
        (m) => m.GenerativeAiModule
      )
  },
  {
    path: 'events',
    loadChildren: () =>
      import('./modules/event/event.module').then((m) => m.EventModule)
  },
  {
    path: 'work-innovation-productivity-skills',
    loadChildren: () =>
      import('./modules/wips/wips.module').then((m) => m.WipsModule)
  },
  {
    path: 'site/ai-futures',
    loadChildren: () =>
      import('./modules/expert-group-portal/expert-group-portal.module').then(
        (m) => m.ExpertGroupPortalModule
      )
  },
  {
    path: 'site/compute-climate',
    loadChildren: () =>
      import('./modules/expert-group-portal/expert-group-portal.module').then(
        (m) => m.ExpertGroupPortalModule
      )
  },
  {
    path: 'site/incidents',
    loadChildren: () =>
      import('./modules/expert-group-portal/expert-group-portal.module').then(
        (m) => m.ExpertGroupPortalModule
      )
  },
  {
    path: 'site/risk-accountability',
    loadChildren: () =>
      import('./modules/expert-group-portal/expert-group-portal.module').then(
        (m) => m.ExpertGroupPortalModule
      )
  },
  {
    path: 'site/health',
    loadChildren: () =>
      import('./modules/expert-group-portal/expert-group-portal.module').then(
        (m) => m.ExpertGroupPortalModule
      )
  },
  {
    path: 'site/data-privacy',
    loadChildren: () =>
      import('./modules/expert-group-portal/expert-group-portal.module').then(
        (m) => m.ExpertGroupPortalModule
      )
  },
  {
    path: 'ai-principles',
    loadChildren: () =>
      import('./modules/principle/principle.module').then(
        (m) => m.PrincipleModule
      )
  },
  {
    path: 'policy-areas',
    loadChildren: () =>
      import('./modules/policy-area/policy-area.module').then(
        (m) => m.PolicyAreaModule
      )
  },
  {
    path: 'ai-publications',
    loadChildren: () =>
      import('./modules/ai-publications/ai-publication.module').then(
        (m) => m.AIPublicationModule
      )
  },
  {
    path: 'policy-initiatives',
    loadChildren: () =>
      import('./modules/policy-initiatives/policy-initiative.module').then(
        (m) => m.PolicyInitiativeModule
      )
  },
  {
    path: 'wonk',
    loadChildren: () =>
      import('./modules/ai-wonk-blog/ai-wonk-blog.module').then(
        (m) => m.AiWonkBlogModule
      )
  },
  {
    path: 'incidents',
    loadChildren: () =>
      import('./modules/incidents-monitor/incidents-monitor.module').then(
        (m) => m.IncidentsMonitorModule
      )
  },

  // Pages.
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'about/the-context',
    component: AboutComponent,
    data: {
      slug: 'the-context'
    }
  },
  {
    path: 'about/what-we-do',
    component: AboutComponent,
    data: {
      slug: 'what-we-do'
    }
  },
  {
    path: 'about/background',
    component: AboutComponent,
    data: {
      slug: 'background'
    }
  },
  {
    path: 'about/partners',
    component: PartnersComponent
  },
  {
    path: 'survey',
    component: SurveyComponent
  },
  {
    path: 'classificationsurvey',
    component: ClassificationSurveyComponent
  },
  {
    path: 'trends-and-data',
    component: TrendsAndDataComponent
  },
  {
    path: 'oecd-metrics-and-methods',
    component: OecdMetricsAndMethodsComponent
  },
  {
    path: 'data',
    component: LiveDataFromPartnersComponent
  },
  {
    path: 'resources',
    component: ResourcesOnAiComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },

  // AI Index.
  {
    path: 'site/ai-index',
    component: AiIndexComponent
  },

  // Working Group.
  {
    path: 'network-of-experts',
    component: NetworksOfExpertsComponent
  },
  {
    path: 'network-of-experts/working-group/:id',
    component: WorkingGroupDetailComponent
  },

  // Participant List.
  {
    path: 'list-of-participants-oecd-expert-group-on-ai',
    component: ParticipantListComponent
  },

  // Videos.
  {
    path: 'videos',
    component: VideoListComponent
  },

  // Authors.
  {
    path: 'community',
    component: AuthorListComponent
  },
  {
    path: 'community/:authorSlug',
    component: AuthorDetailComponent
  },

  // Errors.
  {
    path: '404',
    component: Error404Component
  },

  // Redirect old URL for dynamic pages.
  {
    path: 'p/:slug',
    redirectTo: ':slug'
  },

  {
    path: 'wips',
    redirectTo: 'work-innovation-productivity-skills'
  },

  {
    path: 'generative-ai',
    redirectTo: 'genai'
  },
  {
    path: 'genAI',
    redirectTo: 'genai'
  },
  {
    path: 'privacy',
    redirectTo: 'site/data-privacy'
  },
  {
    path: 'partners',
    redirectTo: 'about/partners'
  },

  {
    path: 'principles',
    redirectTo: 'ai-principles'
  },

  // Expert Group Portal.
  {
    path: 'network-of-experts/ai-futures',
    redirectTo: 'site/ai-futures'
  },

  {
    path: 'network-of-experts/compute-climate',
    redirectTo: 'site/compute-climate'
  },

  {
    path: 'network-of-experts/risk-accountability',
    redirectTo: 'site/risk-accountability'
  },

  {
    path: 'network-of-experts/incidents',
    redirectTo: 'site/incidents'
  },

  // Dynamic pages.
  {
    path: ':slug',
    component: PageDetailComponent
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
